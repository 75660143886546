<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <div v-if="is_loading_content" class="col-12">
          <Skeleton
            v-if="is_loading_content"
            width="100%"
            height="800px"
          ></Skeleton>
        </div>
        <div v-else class="col-12">
          <div class="grid grid-nogutter">
            <div class="col-6 text-left">
              <h5>Order Details #{{ detail_transaction.number }}</h5>
            </div>
            <div class="col-6 text-right">
              <Tag
                v-if="detail_transaction.is_vip_transaction"
                class="mr-2 uppercase"
                severity="warning"
                :value="detail_transaction.is_vip_transaction ? 'vip' : ''"
              ></Tag>
              <Tag
                class="mr-2 uppercase"
                :severity="
                  detail_transaction.status == 'cancelled'
                    ? 'danger'
                    : 'primary'
                "
                :value="detail_transaction.status"
              ></Tag>
            </div>
          </div>
          <TabView>
            <TabPanel header="Order Details">
              <div class="col-12">
                <div class="card p-fluid">
                  <h5>Order Sumary</h5>

                  <div class="field">
                    <label for="email1">order number</label>
                    <InputText
                      v-model="detail_transaction.number"
                      id="email1"
                      disabled
                      type="text"
                    />
                  </div>
                  <div class="field">
                    <label for="email1">buyer username</label>
                    <InputText
                      id="email1"
                      :modelValue="buyer_data.username"
                      disabled
                      type="text"
                    />
                  </div>
                  <div class="field">
                    <label for="email1">buyer email</label>
                    <InputText
                      id="email1"
                      :modelValue="buyer_data.email"
                      disabled
                      type="text"
                    />
                  </div>
                  <div class="field">
                    <label for="email1">sub total</label>
                    <InputText
                      id="email1"
                      disabled
                      :modelValue="
                        formatRupiah(detail_transaction.sub_total, 'Rp.')
                      "
                      type="text"
                    />
                  </div>
                  <div class="field">
                    <label for="email1">shipping fee</label>
                    <InputText
                      id="email1"
                      disabled
                      :modelValue="
                        formatRupiah(
                          detail_transaction.shipping_fee
                            ? detail_transaction.shipping_fee
                            : 0,
                          'Rp.'
                        )
                      "
                      type="text"
                    />
                  </div>

                  <div v-if="detail_transaction.promo[0]" class="field">
                    <label for="email1">voucher code</label>
                    <InputText
                      id="email1"
                      disabled
                      :modelValue="detail_transaction.promo[0].voucher_code"
                      type="text"
                    />
                  </div>
                  <div v-if="detail_transaction.promo[0]" class="field">
                    <label for="email1">discount total</label>
                    <InputText
                      id="email1"
                      disabled
                      :modelValue="
                        formatRupiah(
                          detail_transaction.discount
                            ? detail_transaction.discount
                            : 0,
                          'Rp.'
                        )
                      "
                      type="text"
                    />
                  </div>
                  <div class="field">
                    <label for="email1">grand total</label>
                    <InputText
                      id="email1"
                      disabled
                      :modelValue="
                        formatRupiah(detail_transaction.grand_total, 'Rp.')
                      "
                      type="text"
                    />
                  </div>

                  <div
                    v-if="
                      detail_transaction.status != 'cancelled' &&
                      detail_transaction.status != 'finish'
                    "
                    class="col-12 text-center"
                  >
                    <Button
                      :loading="isloadingpickup"
                      @click="HandleTransaction(detail_transaction.status)"
                      :label="`set as ${
                        detail_transaction.status == 'pending_payment'
                          ? 'process'
                          : detail_transaction.status == 'process'
                          ? 'shipped'
                          : 'finish'
                      }`"
                      class="
                        p-button-outlined p-button-info
                        mr-2
                        mb-2
                        text-sm
                        uppercase
                      "
                    />

                    <Divider layout="horizontal"> </Divider>
                    <Button
                      :loading="isloadingpickup"
                      @click="showModalDeleteItem = true"
                      label="set as cancelled"
                      class="
                        p-button-outlined p-button-danger
                        text-sm
                        mr-2
                        mb-2
                        uppercase
                      "
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Shipping & Billing Info">
              <div class="col-12">
                <div class="card">
                  <div class="grid">
                    <div class="col-5 flex justify-content-center">
                      <div class="col-12">
                        <Card>
                          <template v-slot:title>
                            <div
                              class="
                                flex
                                align-items-center
                                justify-content-between
                                mb-0
                              "
                            >
                              <h5>Shipping Address</h5>
                              <!-- <Button
                                icon="pi  pi-pencil"
                                class="p-button-text"
                                @click="toggle"
                              /> -->
                            </div>
                          </template>

                          <template v-slot:content>
                            <p
                              style="font-weight: bold"
                              class="line-height-3 m-0"
                            >
                              {{
                                detail_transaction.shipping_address
                                  .receipent_name
                              }}
                            </p>
                            <p
                              style="font-weight: bold"
                              class="line-height-3 m-0"
                            >
                              {{ detail_transaction.shipping_address.phone }}
                            </p>
                            <p class="line-height-3 m-0">
                              {{
                                detail_transaction.shipping_address.address +
                                "," +
                                detail_transaction.shipping_address.state +
                                "," +
                                detail_transaction.shipping_address.city +
                                "," +
                                detail_transaction.shipping_address.province +
                                "," +
                                detail_transaction.shipping_address.zip_code
                              }}
                            </p>
                          </template>
                        </Card>
                      </div>
                    </div>

                    <div class="col-2">
                      <Divider layout="vertical">
                        <b>OR</b>
                      </Divider>
                    </div>
                    <div
                      class="col-5 align-items-center justify-content-center"
                    >
                      <div class="col-12">
                        <Card>
                          <template v-slot:title>
                            <div
                              class="
                                flex
                                align-items-center
                                justify-content-between
                                mb-0
                              "
                            >
                              <h5>Billing Address</h5>
                              <!-- <Button
                                icon="pi  pi-pencil"
                                class="p-button-text"
                                @click="toggle"
                              /> -->
                            </div>
                          </template>

                          <template v-slot:content>
                            <p
                              style="font-weight: bold"
                              class="line-height-3 m-0"
                            >
                              {{
                                detail_transaction.billing_address
                                  .receipent_name
                              }}
                            </p>
                            <p
                              style="font-weight: bold"
                              class="line-height-3 m-0"
                            >
                              {{ detail_transaction.billing_address.phone }}
                            </p>
                            <p class="line-height-3 m-0">
                              {{
                                detail_transaction.billing_address.address +
                                "," +
                                detail_transaction.billing_address.state +
                                "," +
                                detail_transaction.billing_address.city +
                                "," +
                                detail_transaction.billing_address.province +
                                "," +
                                detail_transaction.billing_address.zip_code
                              }}
                            </p>
                          </template>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
                <Card>
                  <template v-slot:title>
                    <div
                      class="
                        flex
                        align-items-center
                        justify-content-between
                        mb-0
                      "
                    >
                      <h5>Shipping info</h5>
                      <Tag
                        class="mr-2 uppercase"
                        severity="
                        info  
                        "
                        :value="
                          detail_transaction.shipping[0].status
                            ? detail_transaction.shipping[0].status
                            : 'untracked'
                        "
                      ></Tag>
                    </div>
                  </template>

                  <template v-slot:content>
                    <div class="p-fluid formgrid grid">
                      <div class="field col-12 md:col-4">
                        <label for="firstname2">Expedition Name</label>
                        <InputText
                          id="firstname2"
                          type="text"
                          disabled
                          v-model="shipping_detail.courier"
                        />
                      </div>
                      <div class="field col-12 md:col-4">
                        <label for="address">Service</label>
                        <InputText
                          id="firstname2"
                          type="text"
                          disabled
                          v-model="shipping_detail.service"
                        />
                      </div>
                      <div class="field col-12 md:col-4">
                        <label for="city">Cost</label>
                        <InputText
                          id="firstname3"
                          type="text"
                          disabled
                          v-model="shipping_detail.cost"
                        />
                      </div>
                      <div class="field col-12 md:col-4">
                        <label for="city">Estimation Time</label>
                        <InputText
                          id="firstname4"
                          type="text"
                          disabled
                          v-model="shipping_detail.est"
                        />
                      </div>
                      <div class="field col-12 md:col-4">
                        <label for="city">Resi</label>
                        <InputText
                          id="firstname4"
                          type="text"
                          disabled
                          v-model="shipping_detail.resi"
                        />
                      </div>
                      <div class="field col-12">
                        <label for="lastname2"
                          >Description (shipping note only showing for
                          admin)</label
                        >
                        <Textarea
                          id="address"
                          v-model="shipping_detail.description"
                          rows="4"
                        />
                      </div>
                      <div
                        class="field col-12"
                        style="display: flex; justify-content: center"
                      >
                        <div
                          v-if="detail_transaction.status == 'process'"
                          class="col-3"
                        >
                          <Button
                            type="button"
                            class="mr-2 mb-2"
                            label="Request Pickup"
                            icon="pi pi-car"
                            :loading="isloadingpickup"
                            @click="OpenModalPickup"
                          />

                          <Divider layout="horizontal" align="center">
                            <p>OR</p>
                          </Divider>
                          <Button
                            type="button"
                            class="mr-2 mb-2 p-button-info"
                            label="Manual Input"
                            icon="pi pi-car"
                            :loading="isloadingpickup"
                            @click="OpenModalManual"
                          />
                        </div>
                        <div
                          v-if="shipping_detail.status == 'sent'"
                          class="col-3"
                        >
                          <Button
                            type="button"
                            class="mr-2 mb-2 p-button-info"
                            label="Track Resi"
                            icon="pi pi-car"
                            :loading="isloadingpickup"
                            @click="HandleTrack"
                          />
                          <Divider layout="horizontal" align="center">
                            <p>OR</p>
                          </Divider>
                          <Button
                            type="button"
                            class="mr-2 mb-2"
                            label="Print Label"
                            icon="pi pi-download"
                            :loading="isloadingpickup"
                            @click="PrintLabel"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
            </TabPanel>
            <TabPanel header="Products">
              <div class="col-12">
                <div class="card">
                  <h5>Products</h5>
                  <DataTable
                    :value="detail_transaction.transaction_detail"
                    v-model:expandedRows="expandedRows"
                    dataKey="id"
                    responsiveLayout="scroll"
                  >
                    <Column field="name" header="Name" :sortable="true">
                      <template #body="slotProps">
                        {{ slotProps.data.product_snapshoot.name }}
                      </template>
                    </Column>
                    <Column header="Image">
                      <template #body="slotProps">
                        <img
                          :src="
                            baseUrl.baseURL +
                            '/product/' +
                            slotProps.data.product_snapshoot.images[0]?.path
                          "
                          :alt="slotProps.data.product_snapshoot.name"
                          class="shadow-2"
                          width="100"
                        />
                      </template>
                    </Column>
                    <Column field="qty" header="Qty" :sortable="true">
                      <template #body="slotProps">
                        {{ slotProps.data.qty }}
                      </template>
                    </Column>
                    <Column field="price" header="Price" :sortable="true">
                      <template #body="slotProps">
                        {{
                          formatRupiah(
                            parseInt(
                              slotProps.data.total_price
                                ? slotProps.data.total_price
                                : 0
                            ),
                            "Rp."
                          )
                        }}
                        <!-- {{ slotProps.data.total_price }} -->
                      </template></Column
                    >
                    <Column
                      field="total price"
                      header="TOTAL Price"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        {{
                          formatRupiah(
                            parseInt(
                              slotProps.data.total_price
                                ? slotProps.data.total_price
                                : 0
                            ) * parseInt(slotProps.data.qty),
                            "Rp."
                          )
                        }}
                        <!-- {{
                          parseInt(slotProps.data.total_price) *
                          parseInt(slotProps.data.qty)
                        }} -->
                      </template></Column
                    >

                    <Column :expander="true" headerStyle="width: 3rem" />

                    <template #expansion="slotProps">
                      <Card
                        class="mb-2"
                        v-for="data in slotProps.data.product_attributes"
                        :key="data._id"
                      >
                        <template v-slot:title>
                          <div
                            class="
                              flex
                              align-items-center
                              justify-content-between
                              mb-0
                            "
                          >
                            <h5>{{ data.attr_id.attr_name }}</h5>
                            <p>{{ data.attr_id.attr_description }}</p>

                            <Button
                              icon="pi pi-plus"
                              class="p-button-text"
                              @click="toggle"
                            />
                          </div>
                          <Menu
                            id="config_menu"
                            ref="menu"
                            :model="cardMenu"
                            :popup="true"
                          />
                        </template>

                        <template v-slot:content>
                          <p>{{ data.selected_attr_list_id.name }}</p>
                          <Image
                            v-if="!data.is_call_api"
                            :src="
                              baseUrl.baseURL +
                              '/product/' +
                              data.selected_attr_list_id.desktop_img
                            "
                            :alt="data.selected_attr_list_id.name"
                            width="250"
                            height="250"
                            preview
                          />
                          <div v-if="!data.is_use_input_value" class="">
                            <p>{{ data.qty }} pcs</p>
                          </div>
                          <div v-if="data.is_use_input_value" class="">
                            <p style="margin-top: 20px">selected attribute</p>
                            <p v-for="(set, i) in data.input_value" :key="i">
                              <span
                                v-for="(dataset, index) in Object.keys(set)"
                                :key="index"
                              >
                                {{ dataset + " : " + set[dataset] + " " }}
                              </span>
                            </p>
                            <div v-if="data.is_call_api">
                              <Fieldset
                                legend="Detail"
                                @click="GetCartAttr(data.cart_attr_id)"
                                :toggleable="true"
                                :collapsed="true"
                              >
                                <div>
                                  <p v-if="!attr_cart">loading..</p>
                                  <!-- <p>
                                    {{ attr_cart?.data?.data?.reference_id }}
                                  </p> -->
                                  <iframe
                                    v-if="attr_cart"
                                    id="smart-cut-vis"
                                    :src="
                                      `https://workshop-buddy.com/embed/?result=` +
                                      attr_cart?.data?.data?.reference_id
                                    "
                                    height="500"
                                    width="800"
                                  ></iframe>
                                  <Button
                                    style="margin-top: 10px"
                                    label="Download Pdf"
                                    @click="
                                      downloadPdf(
                                        attr_cart?.data?.data?.reference_id
                                      )
                                    "
                                    class="p-button-outlined p-button-primary"
                                  />
                                </div>
                              </Fieldset>
                            </div>
                            <p style="margin-top: 20px">
                              total : {{ data.qty }} pcs
                            </p>
                          </div>

                          <Divider layout="horizontal" align="center">
                          </Divider>
                          <div class="grid grid-nogutter">
                            <div class="col-6 text-left">
                              <p style="font-weight: bold">PRICE PER PCS</p>
                            </div>
                            <div class="col-6 text-right">
                              <p style="font-weight: bold">
                                {{ data.price }}
                              </p>
                            </div>
                          </div>
                          <div class="grid grid-nogutter">
                            <div class="col-6 text-left">
                              <p style="font-weight: bold">TOTAL</p>
                            </div>
                            <div class="col-6 text-right">
                              <p style="font-weight: bold">
                                {{ parseInt(data.qty) * data.price }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </Card>
                    </template>
                  </DataTable>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Order History">
              <Timeline
                :value="customEvents"
                align="alternate"
                class="customized-timeline"
              >
                <template>
                  <span
                    class="
                      flex
                      w-2rem
                      h-2rem
                      align-items-center
                      justify-content-center
                      text-white
                      border-circle
                      z-1
                      shadow-2
                    "
                    style="background-color: blue"
                  >
                    <i class="pi pi-shopping-cart"></i>
                  </span>
                </template>
                <template #content="slotProps">
                  <Card>
                    <template #title>
                      {{ slotProps.item.msg }}
                    </template>
                    <template #subtitle>
                      {{ CreateDate(slotProps.item.created_at) }}
                    </template>
                    <template #content>
                      <p>
                        Action By :
                        <span class="font-bold uppercase">{{
                          slotProps.item.user_id
                            ? slotProps.item.user_id.username
                            : "system"
                        }}</span>
                      </p>
                    </template>
                  </Card>
                </template>
              </Timeline>
            </TabPanel>
          </TabView>
        </div>
      </div>

      <!-- modal request pickup -->
      <Dialog
        header="request pickup details"
        v-model:visible="reqpickupmodal"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '30vw' }"
        :modal="true"
      >
        <Skeleton v-if="loading_modal" width="100%" height="200px"></Skeleton>

        <Card v-if="!loading_modal">
          <template v-slot:content>
            <p>there is your pickup address :</p>
            <p style="font-weight: bold" class="line-height-3 m-0">
              {{ address.receipent_name }}
            </p>
            <p style="font-weight: bold" class="line-height-3 m-0">
              {{ address.phone }}
            </p>
            <p class="line-height-3 m-0">
              {{
                address.address +
                "," +
                address.state +
                "," +
                address.city +
                "," +
                address.province +
                "," +
                address.zip_code
              }}
            </p>
            <Button
              type="button"
              class="mt-5 p-button-info"
              label="Request Now"
              icon="pi pi-inbox"
              :loading="isloadingpickup"
              @click="RequestPickup"
            />
          </template>
        </Card>
      </Dialog>

      <!-- modal manual input -->
      <Dialog
        header="Dialog"
        v-model:visible="manualpickupmodal"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '30vw' }"
        :modal="true"
      >
        <div>
          <p>Resi</p>
          <InputText
            type="text"
            v-model="resi"
            placeholder="Resi Number"
          ></InputText>
        </div>

        <template #footer>
          <Button
            :loading="isloadingpickup"
            label="Mark As Shipped"
            @click="HandleSaveManual()"
            icon="pi pi-check"
            class="p-button-outlined"
          />
        </template>
      </Dialog>

      <!-- modal confirm cancel-->
      <Dialog
        v-model:visible="showModalDeleteItem"
        :style="{ width: '450px' }"
        header="Delete Menu Confirm ?"
        :modal="true"
      >
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span>Are you sure you want to cancel this transaction ?</span>
        </div>
        <template #footer>
          <Button
            @click="showModalDeleteItem = !showModalDeleteItem"
            label="No"
            icon="pi pi-times"
            class="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            :loading="isLoading"
            @click="HandleTransaction('cancelled')"
          />
        </template>
      </Dialog>

      <!-- modal track resi-->
      <Dialog
        v-model:visible="ShowModalTrack"
        :style="{ width: '450px' }"
        header="Track Resi"
        :modal="true"
      >
        <Card>
          <template #title>Resi : {{ shipping_detail.resi }} </template>
          <template #content>
            <Timeline :value="track_data">
              <template #opposite="slotProps">
                <small class="p-text-secondary">{{
                  slotProps.item.date
                }}</small>
              </template>
              <template #content="slotProps">
                {{ slotProps.item.status }}
              </template>
            </Timeline>
          </template>
        </Card>
      </Dialog>
    </div>
  </div>
</template>

<script>
import BaseURL from "../../api/BaseURL";
import TransactionApi from "../../api/Transaction";
import moment from "moment";

export default {
  data() {
    return {
      is_loading_tracking: false,
      showModalDeleteItem: false,
      ShowModalTrack: false,
      resi: null,
      manualpickupmodal: false,
      is_loading_content: false,
      reqpickupmodal: false,
      customEvents: [],
      baseUrl: BaseURL,
      expandedRows: [],

      isloadingpickup: false,
      showModalSaveAll: false,
      form: {
        name: "",
        weight: null,
        is_pre_order: false,
        pre_order_day: 0,
        description: "",
        product_attributes: [],
        is_use_variant: false,
        is_active: false,
        is_big_stuff: false,
        categories: [],
        collections: [],
      },
      detail_transaction: {},
      shipping_detail: {},
      attribute_list: [],

      multiselectValue: [],
      multiselectValues: [],
      buyer_data: {},
      file: null,
      preview: null,
      loading: false,
      upload_status: false,
      products: [],
      address: {},
      loading_modal: false,
      track_data: [
        {
          status: "Ordered",
          date: "15/10/2020 10:30",
        },
        {
          status: "Processing",
          date: "15/10/2020 14:00",
        },
        {
          status: "Shipped",
          date: "15/10/2020 16:15",
        },
        {
          status: "Delivered",
          date: "16/10/2020 10:00",
        },
      ],
      attr_cart: {},
    };
  },
  mounted() {
    // this.getAttributes();
  },
  async created() {
    this.is_loading_content = true;
    await this.GetDetails();
    this.is_loading_content = false;
  },

  methods: {
    async HandleTrack() {
      this.ShowModalTrack = true;
    },
    async PrintLabel() {
      let saveresi = await TransactionApi.PrintLabel({
        transaction_id: this.$route.params.id,
      });
      const blob = new Blob([saveresi.data], { type: "application/pdf" });
      const objectUrl = window.URL.createObjectURL(blob);
      window.open(objectUrl);
    },
    async HandleTransaction(action) {
      let data = { transaction_id: this.$route.params.id };
      this.isloadingpickup = true;

      if (action == "process") {
        this.manualpickupmodal = true;
        this.isloadingpickup = false;
      } else if (action == "pending_payment") {
        // set as paid

        let saveresi = await TransactionApi.SetPaid(data);
        if (saveresi.data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Success!",
            detail: "Update to paid success",
            life: 4000,
          });
          this.is_loading_content = true;
          await this.GetDetails();
          this.is_loading_content = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: "Failed to update paid",
            life: 4000,
          });
        }
        this.isloadingpickup = false;
      } else if (action == "shipped") {
        // set as finish
        let saveresi = await TransactionApi.SetFinish(data);
        if (saveresi.data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Success!",
            detail: "Update  to finish Success",
            life: 4000,
          });
          this.is_loading_content = true;
          await this.GetDetails();
          this.is_loading_content = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: "Failed to update finish",
            life: 4000,
          });
        }
        this.isloadingpickup = false;
      } else if (action == "cancelled") {
        // set as cancel
        let saveresi = await TransactionApi.SetCancel(data);
        if (saveresi.data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Success!",
            detail: "change status cancelled Success",
            life: 4000,
          });
          this.is_loading_content = true;
          await this.GetDetails();
          this.is_loading_content = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: "Failed to cancel transaction",
            life: 4000,
          });
        }
        this.isloadingpickup = false;
      }
    },
    async HandleSaveManual() {
      this.isloadingpickup = true;
      try {
        let data = {
          transaction_id: this.$route.params.id,
          resi: this.resi,
        };
        let saveresi = await TransactionApi.SetShipped(data);
        if (saveresi.data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Success!",
            detail: "Update Shipping Success",
            life: 4000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: "Failed to update shipping details",
            life: 4000,
          });
        }
        this.isloadingpickup = false;

        this.manualpickupmodal = false;
      } catch (error) {
        this.isloadingpickup = false;
      }
    },
    async downloadPdf(ref_id) {
      const getPdf = await TransactionApi.DownloadPdf({
        reference_id: ref_id,
        transaction_id: this.$route.params.id,
      });

      const blob = new Blob([getPdf.data], { type: "application/pdf" });
      const objectUrl = window.URL.createObjectURL(blob);
      window.open(objectUrl);
    },
    async RequestPickup() {
      this.isloadingpickup = true;
      const response = await TransactionApi.RequestPickup({
        transaction_id: this.$route.params.id,
      });
      if (response.data.status == 200) {
        this.$toast.add({
          severity: "success",
          summary: "Success!",
          detail: "Request Pickup Success",
          life: 4000,
        });
        await this.GetDetails();
        this.isloadingpickup = false;
        this.reqpickupmodal = false;
      } else {
        this.$toast.add({
          severity: "error",
          summary: "try again later!",
          detail: response.data.message,
          life: 4000,
        });
        this.isloadingpickup = false;
        this.reqpickupmodal = false;
      }
    },
    async OpenModalPickup() {
      this.reqpickupmodal = true;
      this.loading_modal = true;
      let get_address = await TransactionApi.GetAddress();
      if (get_address.data.status == 200) {
        this.address = get_address.data.data;
      }
      this.loading_modal = false;
    },
    OpenModalManual() {
      this.manualpickupmodal = true;
    },
    async GetCartAttr(id) {
      try {
        let get_attr_id = await TransactionApi.GetAttrCart({
          cart_attr_id: id,
        });
        this.attr_cart = get_attr_id;
      } catch (error) {
        this.attr_cart = null;
      }
    },
    CreateDate(date) {
      return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
    },
    toggle() {
      this.$refs.menu.toggle(event);
    },
    formatRupiah(angka, prefix) {
      if (!angka && angka !== 0) return;

      angka = angka.toString();
      var number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
    },
    fileHandler(e) {
      this.file = e.target.files[0];
      this.preview = URL.createObjectURL(this.file);
    },
    async GetDetails() {
      try {
        const response = await TransactionApi.GetDetail(this.$route.params.id);
        const response_history = await TransactionApi.GetHistory(
          this.$route.params.id
        );
        if (response_history.data.status === 200) {
          this.customEvents = response_history.data.data;
        }
        if (response.data.status === 200) {
          this.detail_transaction = response.data.data[0];
          this.buyer_data = response.data.data[0].user[0];
          this.shipping_detail = response.data.data[0].shipping[0];
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: "Failed to get transaction details",
            life: 4000,
          });
          this.$router.push("/admin/transaction-management/transaction");
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed!",
          detail: "something went wrong",
          life: 4000,
        });
      }
    },
    async GetHistory() {},
  },
};
</script>
